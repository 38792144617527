<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.cooperationPartnerContract.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.cooperationPartnerContract.list.title"
        ></app-i18n>
      </h1>

      <app-cooperation-partner-contract-list-table
        :isInPojectImplementation="false"
        :releaseMode="true"
      ></app-cooperation-partner-contract-list-table>

      <router-link :to="{ path: '/' }">
        <el-button type="primary">
          <app-i18n code="common.finish"></app-i18n>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import cooperationPartnerContractListTable from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-table.vue';

export default {
  name: 'app-cooperation-partner-contract-list-release-request-page',

  props: ['id'],

  components: {
    [cooperationPartnerContractListTable.name]: cooperationPartnerContractListTable,
  },

  computed: {
    ...mapGetters({
      loading: 'cooperationPartnerContract/list/loading',
      filter: 'cooperationPartnerContract/list/filter',
    }),
  },

  async mounted() {
    return this.doInitalFilter();
  },

  methods: {
    ...mapActions({
      doFetch: 'cooperationPartnerContract/list/doFetch',
    }),

    async doInitalFilter() {
      const filter = {
        projectImplementation: this.id,
      };

      return this.doFetch({
        filter,
      });
    },

    async doFinish() {
      //TODO
      //what to do here
    },
  },
};
</script>

<style>
</style>
